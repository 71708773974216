<template>
  <section class="offer-create">
    <!-- title -->
    <h1 class="offer-create__title page__title">
      Коммерческое предложение <span v-if="edit && offer">№{{ offer.id }}</span>
      <span class="offer-create__title-date" v-if="edit && offer">{{
        offerDate
      }}</span>
    </h1>
    <div class="offer-create-wrapper page-wrapper">
      <!-- fields -->
      <div class="offer-create-fields fields">
        <div class="offer-create-fields-row fields-row">
          <base-input
            :label="'Контрагент'"
            :id="'offerCreatecounterparty'"
            :suggestions="counterparties"
            :search="true"
            @setSuggestion="getCounterparty('', $event)"
            @keypressEnter="getCounterparty('')"
            @input="getCounterparty('search')"
            @clear="clearConterparties"
            v-model="data.counterparty"
          />
        </div>
        <div class="offer-create-fields-row fields-row">
          <base-input
            :label="'ИНН'"
            :id="'offerCreateInn'"
            type="number"
            :max-length="12"
            :disabled="disabledConterpartiesFields"
            :readonly="disabledConterpartiesFields"
            :message="message"
            @input="message = ''"
            v-model="data.inn"
          />
        </div>
        <div class="offer-create-fields-row fields-row _area">
          <base-textarea
            :label="'Адрес'"
            :id="'offerCreateAddress'"
            :disabled="disabledConterpartiesFields"
            :readonly="disabledConterpartiesFields"
            v-model="data.address"
          />
        </div>
        <div class="offer-create-fields-row fields-row">
          <base-input
            :label="'Телефон'"
            input-type="text"
            :id="'offerCreatePhone'"
            :disabled="disabledConterpartiesFields"
            :readonly="disabledConterpartiesFields"
            v-model="data.phone"
          />
        </div>
        <div class="offer-create-fields-row fields-row">
          <base-input
            :label="'Электронная почта'"
            :id="'offerCreateEmail'"
            :disabled="disableEmailInput"
            :readonly="disableEmailInput"
            @blur="disableActions = false"
            v-model="data.email"
          />
        </div>
        <!-- create conteparty -->
        <base-button
          type="filled"
          class="offer-create-fields__button"
          :disabled="disabledConterpartiesFields || !data.counterparty"
          @clicked="createConterparty"
        >
          Создать контрагента
        </base-button>
        <div class="offer-create-fields-row fields-row">
          <base-input
            :label="'Контактное лицо'"
            :id="'offerCreateContact'"
            :disabled="data.contactPerson && true"
            :readonly="data.contactPerson && true"
            v-model="data.contactPerson"
          />
        </div>
        <div class="offer-create-fields-row fields-row _validy">
          <base-input
            :label="'Срок действия'"
            :short="true"
            :id="'offerCreateValidy'"
            :disabled="data.validy && true"
            :readonly="data.validy && true"
            v-model="data.validy"
          />
        </div>
        <div class="offer-create-fields-row fields-row _area">
          <base-input
            :label="'Конечный заказчик'"
            :id="'offerCreateEndCustomer'"
            :suggestions="customers"
            :search="true"
            @setSuggestion="getEndCustomer('', $event)"
            @keypressEnter="getEndCustomer('')"
            @input="getEndCustomer('search')"
            @clear="clearCustomers"
            v-model="data.endCustomer"
          />
        </div>
        <div class="offer-create-fields-row fields-row">
          <div
            class="base-input-row"
            v-if="!disabledCustomerField && data.endCustomer"
          >
            <label for="">Регион</label>
            <base-select
              class="input"
              :options="regions"
              :placeholder="'Регион'"
              v-model="data.region"
            />
          </div>
          <base-input
            :label="'Регион'"
            :id="'offerCreateRegion'"
            :disabled="disabledCustomerField"
            :readonly="disabledCustomerField"
            v-model="data.region"
            v-else
          />
        </div>
        <div class="offer-create-fields-row fields-row">
          <base-input
            :label="'ИНН'"
            :id="'offerCustomerInn'"
            type="number"
            :max-length="12"
            :message="createCustomerInn"
            :disabled="disabledCustomerField"
            :readonly="disabledCustomerField"
            @input="createCustomerInn = ''"
            v-model="data.customerInn"
          />
        </div>
        <!-- create end customer -->
        <base-button
          type="filled"
          class="offer-create-fields__button"
          :disabled="
            (currentCustomer && currentCustomer?.id) || !data.endCustomer
          "
          @clicked="createEndCustomer"
        >
          Создать конечного заказчика
        </base-button>
        <div class="offer-create-fields-row _price fields-row">
          <span>Тип цены</span>
          <base-select
            :options="priceTypes"
            v-model="priceType"
            :disabled="products.length > 0"
            @change="productSuggestions = []"
          />
        </div>
        <div class="offer-create-fields-row fields-row _area">
          <base-textarea
            :label="'Комментарий'"
            :id="'offerCreateComment'"
            v-model="comment"
          />
        </div>
      </div>
      <!-- products -->
      <div class="offer-create-products">
        <h2 class="offer-create-products__title">Продукция</h2>
        <div class="offer-create-products-row article-row">
          <div class="offer-create-products-field article-row-field">
            <base-input
              :id="'offerCreatecounterpartyProduct'"
              :placeholder="'Артикул или наименование'"
              :search="true"
              :suggestions="productSuggestions"
              :disbled="!priceType"
              @setSuggestion="getProducts('', $event)"
              @keypressEnter="getProducts('')"
              @input="getProducts('search')"
              @clear="
                (productsSearch = ''),
                  ((productSuggestions = []), (productToSend = {}))
              "
              v-model="productsSearch"
            />
          </div>
          <div class="offer-create-products-add article-row-button">
            <base-button
              :type="'outlined'"
              @clicked="productAddCheck && addProduct()"
            >
              Добавить
            </base-button>
          </div>
        </div>
        <!-- products table -->
        <offer-create-table
          :products="products"
          :price-type="priceType"
          @removeProduct="removeProduct"
        />
      </div>
      <!-- company -->
      <div class="offer-create-company" :class="{ filled: products.length }">
        <h3 class="offer-create-company__title">Выбрать компанию</h3>
        <div class="offer-create-company__select">
          <base-select
            :options="companies"
            v-model="data.company"
            :borderOnly="true"
          />
        </div>
      </div>
      <!-- type -->
      <div class="offer-create-type">
        <h4 class="offer-create-type__title">Сохранить</h4>
        <div class="offer-create-types">
          <div class="base-check">
            <input
              type="radio"
              id="offerCreatePdf"
              value="pdf"
              v-model="data.tech_docs_format"
            />
            <label for="offerCreatePdf" class="upp"> pdf </label>
          </div>
          <div class="base-check _excel">
            <input
              type="radio"
              id="offerCreateExcel"
              value="excel"
              v-model="data.tech_docs_format"
            />
            <label for="offerCreateExcel"> Excel </label>
          </div>
        </div>
      </div>
      <!-- hide discount -->
      <div class="offer-create-type">
        <div class="base-check">
          <input
            type="checkbox"
            id="hideDiscount"
            v-model="data.hideDiscount"
          />
          <label for="hideDiscount">скрыть скидку</label>
        </div>
      </div>
      <!-- type -->
      <div class="offer-create-type _get-ready">
        <h4 class="offer-create-type__title _get-ready">Подготовить</h4>
        <div class="offer-create-types _get-ready">
          <div class="base-check">
            <input type="checkbox" id="offerReadyTz" v-model="data.tz" />
            <label for="offerReadyTz" class="upp"> тз </label>
          </div>
          <div class="base-check upp _ru">
            <input type="checkbox" id="offerReadyRu" v-model="data.ru" />
            <label for="offerReadyRu" class="upp"> ру </label>
          </div>
        </div>
        <div class="offer-create-tzType" v-if="data.tz">
          <h5>Тип</h5>
          <div>
            <div class="base-check radio">
              <input
                type="radio"
                id="offerCreateKtru"
                value="ktru"
                v-model="data.tzType"
              />
              <label for="offerCreateKtru" class="upp"> ктру </label>
            </div>
            <div class="base-check radio">
              <input
                type="radio"
                id="offerCreateTu"
                value="tu"
                v-model="data.tzType"
              />
              <label for="offerCreateTu" class="upp"> ту </label>
            </div>
            <div class="base-check radio">
              <input
                type="radio"
                id="offerCreateKtrus"
                value="ktrus"
                v-model="data.tzType"
              />
              <label for="offerCreateKtrus">
                <span class="upp">ктру</span> сокр.
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- actions -->
    <div class="offer-create-actions page-buttons">
      <div class="offer-create__send">
        <base-button
          type="filled"
          :disabled="disabledSubmit || disableActions"
          @clicked="sendToEmail(edit ? 'saved' : '')"
        >
          Отправить
        </base-button>
      </div>
      <div class="offer-create__save">
        <base-button
          type="outlined"
          :disabled="disabledSubmit"
          @clicked="saveOffer"
        >
          Сохранить
        </base-button>
      </div>
      <div class="offer-create__dl">
        <base-button
          :disabled="disabledSubmit || disableActions"
          @clicked="download(edit ? 'saved' : '')"
          type="outlined"
        >
          Скачать
        </base-button>
      </div>
    </div>
  </section>
  <base-modal :open="openModal" :text="modalText" @close="closeModal" />
</template>

<script>
import { useMeta } from "vue-meta";
import BaseTextarea from "@/components/common/Base.Textarea.vue";
import BaseSelect from "@/components/common/Base.Select.vue";
import OfferCreateTable from "./components/OfferCreateTable.vue";
import { formatUrl } from "@/helpers/index";

export default {
  components: {
    BaseTextarea,
    BaseSelect,
    OfferCreateTable,
  },
  data() {
    return {
      offer: null,
      data: {
        counterparty: "",
        inn: "",
        address: "",
        phone: "",
        email: "",
        contactPerson: "",
        validy: "",
        endCustomer: "",
        region: "",
        tech_docs_format: "",
        tz: false,
        ru: false,
        tzType: "ktru",
        ready: "",
        hideDiscount: false,
      },
      openModal: false,
      modalText: "Отправлено",
      products: [],
      productsSearch: "",
      message: "",
      createCustomerInn: "",
      companies: [
        {
          key: "Не Выбран",
          value: "",
        },
      ],
      priceTypes: [
        {
          key: "Стандартный (₽)",
          value: "standard",
        },
        {
          key: "Коммерческий (₽)",
          value: "commercial",
        },
        {
          key: "Валютный ($)",
          value: "currency",
        },
      ],
      priceType: "standard",
      comment: "",
      company: "",
      counterparties: [],
      productSuggestions: [],
      currentCounterparty: null,
      customers: [],
      currentCustomer: null,
      productToSend: {},
      disableActions: false,
      regions: [],
    };
  },
  computed: {
    offerDate() {
      const date = new Date(this.offer.created_at);
      const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const week = date.toLocaleString("ru", { month: "long" });
      const year = date.getFullYear();
      return `${day} ${week} ${year}`;
    },
    edit() {
      return this.$route.query.id ?? false;
    },
    disabledSubmit() {
      return (
        !this.currentCounterparty?.id ||
        !this.currentCustomer?.id ||
        !this.data?.company ||
        !this.products?.length ||
        !this.data?.tech_docs_format ||
        false
      );
    },
    productAddCheck() {
      return (
        Object.keys(this.productToSend).length > 0 && this.currentCounterparty
      );
    },
    disabledConterpartiesFields() {
      return (
        Boolean(this.currentCounterparty && this.currentCounterparty?.id) ??
        false
      );
    },
    disabledCustomerField() {
      return Boolean(this.currentCustomer) || false;
    },
    disableEmailInput() {
      return Boolean(this.currentCustomer?.email) || false;
    },
  },
  setup() {
    useMeta({ title: "Страница Коммерческого предложения" });
  },
  mounted() {
    this.edit && this.getOffer();
    this.getCompanies();
    this.getRegions();
    setTimeout(() => {
      this.disableActions = false;
    }, 300);
  },
  methods: {
    // companies
    getCompanies() {
      this.$api.get(`${this.$api.defaults.API}/company`).then((res) => {
        for (let c of res.data.results) {
          const company = {
            key: c.name,
            value: c.id,
          };
          !this.companies.some((i) => i.value == company.value) &&
            company.key &&
            this.companies.push(company);
        }
      });
    },
    // offers
    async getOffer(id) {
      this.$store.dispatch("loading", true);
      await this.$api
        .get(`${this.$api.defaults.API}/offer/${this.$route.query.id ?? id}`)
        .then((res) => {
          this.offer = res.data;
          this.currentCounterparty = this.offer.counterparty ?? "";
          this.setCounterparty(this.offer.counterparty);
          this.data.endCustomer = this.offer.end_customer.name ?? "";
          this.data.region = this.offer.end_customer.region ?? "";
          this.data.customerInn = this.offer.end_customer.inn ?? "";
          this.validy = this.offer.exploration_date ?? "";
          this.currentCustomer = this.offer.end_customer;
          this.products = this.offer.offer_products ?? "";
          this.data.company = res.data.company.id ?? "";
          this.data.tech_docs_format = this.offer.tech_docs_format;
          this.priceType = this.offer.price_type ?? "standard";
          this.comment = this.offer.comment ?? "";
          this.hideDiscount = this.offer.hide_discount ?? false;
          this.$store.dispatch("loading", false);
        })
        .catch((err) => {
          new Error(err);
          this.$store.dispatch("loading", false);
        });
    },
    // products
    getProducts(type = "", product = "") {
      const params = {
        [this.productsSearch && "search"]: this.productsSearch,
        price_type: this.priceType,
      };
      if (type === "search") {
        this.$api
          .get(`${this.$api.defaults.API}/product`, { params })
          .then((res) => {
            this.productSuggestions = [];
            if (this.productsSearch) {
              for (const p of res.data.results) {
                if (type !== "search") this.getProduct();
                if (type == "search") {
                  // get search suggestions
                  if (this.productsSearch) {
                    this.productSuggestions.push({
                      key: p.name,
                      value: p.id,
                    });
                  }
                }
              }
            }
          });
      } else {
        this.$api
          .get(
            `${this.$api.defaults.API}/product/${
              product?.value || this.productsSearch
            }`
          )
          .then((res) => {
            const product = {
              product: res.data.id,
              article: res.data.article,
              name: res.data.name,
              price: `${res.data.price}`,
              discount: this.currentCounterparty?.discount ?? 0,
              count: 1,
              cost: `${res.data.price}`,
              unit: res?.data?.unit === "упак." ? res?.data?.unit : "шт.",
              price_per_package: res?.data?.price_per_package,
            };
            this.productToSend = product;
          });
      }
    },
    // add product
    addProduct() {
      this.$store.dispatch("loading", true);
      this.products.push(this.productToSend);
      this.productToSend = {};
      this.productsSearch = "";
      this.$store.dispatch("loading", false);
    },
    // remove product
    removeProduct(p) {
      const index = this.products?.indexOf?.(p);
      this.products.splice(index, 1);
    },
    // counterparties
    getCounterparty(type = "", counterparty = "") {
      this.message = "";
      if (type == "search") {
        this.counterparties = [];
        if (this.data.counterparty?.length) {
          this.$api
            .get(`${this.$api.defaults.API}/counterparty/search`, {
              params: {
                query: this.data.counterparty,
              },
            })
            .then((res) => {
              if (this.data.counterparty?.length) {
                this.counterparties = res?.data?.map?.((c) => {
                  return {
                    key: c.name || "",
                    value: c.id || "",
                    ...c,
                  };
                });
              }
            });
        }
      } else {
        this.currentCounterparty = counterparty;
        this.setCounterparty(counterparty);
      }
    },
    // set found counterparty product
    setCounterparty(counterparty) {
      this.data.counterparty = counterparty?.name || "";
      this.data.inn = counterparty?.inn || "";
      this.data.address = `${counterparty?.address_street ?? ""}, ${
        counterparty?.address_city ?? ""
      }, ${counterparty?.address_country ?? ""}, ${
        counterparty?.address_index ?? ""
      }`;
      this.data.phone = counterparty?.phone_number
        ? `+${counterparty?.phone_number}`
        : null;
      this.data.email = counterparty?.email;
      this.data.contactPerson = counterparty?.contact_person || "";
    },
    // endCustomers
    getEndCustomer(type = "", customer = "") {
      if (type == "search") {
        this.customers = [];
        if (this.data.endCustomer?.length) {
          this.$api
            .get(`${this.$api.defaults.API}/end-customer/search`, {
              params: {
                query: this.data.endCustomer,
              },
            })
            .then((res) => {
              this.customers = [...(res?.data ?? [])];
              if (this.data.endCustomer?.length) {
                this.customers = res?.data?.map?.((c) => {
                  return {
                    key: c.name || "",
                    value: c.id || "",
                    ...c,
                  };
                });
              }
            });
        }
      } else {
        this.currentCustomer = customer;
        this.data.endCustomer = customer?.name || "";
        this.data.region = customer?.region || "";
        this.data.customerInn = customer?.inn || "";
      }
    },
    // send to email
    sendToEmail(isSave) {
      if (isSave === "saved") {
        if (this.data.email) {
          this.$store.dispatch("loading", true);
          const data = {
            send_to: this.data.email,
            co_format: this.data.tzType,
            required_tech_docs: this.data.tz,
            tech_docs_format: this.data.tech_docs_format,
            required_ru: this.data.ru,
          };
          this.$api
            .post(
              `${this.$api.defaults.API}/offer/${this.offer.id}/send-to-email`,
              data
            )
            .then(() => {
              this.$store.dispatch("loading", false);
              (this.modalText = "Отправлено"), (this.openModal = true);
            })
            .catch((err) => {
              new Error(err);
              this.$store.dispatch("loading", false);
            });
        }
      } else {
        this.saveOffer("send");
      }
    },
    // save offer
    saveOffer(type = "") {
      this.$store.dispatch("loading", true);

      const data = {
        counterparty: this.currentCounterparty?.id ?? null,
        end_customer: this.currentCustomer?.id ?? null,
        company: this.data?.company ?? null,
        offer_products: [
          ...(this.products.map((p) => {
            const price = +p.price;
            const pricePrePackage = +p?.price_per_package;
            const cost =
              p?.unit?.toLowerCase?.() === "упак."
                ? pricePrePackage * +p?.count -
                  (pricePrePackage * +p?.count * p?.discount) / 100
                : price * +p?.count - (price * +p?.count * p?.discount) / 100;

            return {
              [this.edit && "id"]: p.id,
              product: p.product,
              price: price?.toFixed?.(2),
              discount: +p.discount,
              count: +p.count,
              cost: cost?.toFixed?.(2),
              unit: p.unit,
              price_per_package: pricePrePackage?.toFixed?.(2),
            };
          }) ?? []),
        ],
        tech_docs_format: this.data?.tech_docs_format,
        price_type: this.priceType,
        hide_discount: this.data?.hideDiscount,
        comment: this.comment,
      };

      // edit
      if (this.edit) {
        this.$api
          .patch(`${this.$api.defaults.API}/offer/${this.offer.id}`, data)
          .then(async () => {
            this.$store.dispatch("loading", false);
            this.modalText = "Сохранено";
            this.openModal = true;
            await this.getOffer();
            this.disableActions = false;
          })
          .catch((err) => {
            new Error(err);
            this.$store.dispatch("loading", false);
          });
      }
      // create
      else {
        this.$api
          .post(`${this.$api.defaults.API}/offer`, data)
          .then(async (res) => {
            this.$store.dispatch("loading", false);
            this.modalText = "Сохранено";
            if (!type) this.openModal = true;
            this.$store.dispatch("loading", false);
            await this.getOffer(res.data.id);
            this.disableActions = false;

            if (type) {
              type === "download"
                ? await this.download("saved")
                : await this.sendToEmail("saved");
            }

            this.$router.push({
              name: "offerCreate",
              query: { id: res.data.id },
            });
          })
          .catch((res) => {
            new Error(res);
            this.$store.dispatch("loading", false);
          });
      }
    },
    // download
    async download(isSave) {
      if (isSave === "saved") {
        const urls = [];
        await urls?.push(
          formatUrl(this.offer?.[`kp_${this.data.tech_docs_format}_file`])
        );
        // if tz is selected
        if (this.data?.tz) {
          await urls?.push(
            formatUrl(
              this.data?.tzType === "ktru"
                ? this.offer?.krtu_file
                : this.data?.tzType === "tu"
                ? this.offer?.krtu_gost_file
                : this.data?.tzType === "ktrus"
                ? this.offer?.krtu_avg_file
                : ""
            )
          );
        }
        // if ru is selected
        if (this.data?.ru) {
          await urls?.push(formatUrl(this.offer?.ru_file));
        }

        for (const i in urls) {
          setTimeout(() => {
            const a = document.createElement("a");
            a.setAttribute("href", urls?.[i]);
            a.setAttribute("download", "");
            a.setAttribute("target", "_blank");
            a.click();
          }, i * 500);
        }

        return await "downloaded";
      }

      this.saveOffer("download");
    },
    // create conterparty
    createConterparty() {
      console.log(this.currentCounterparty);
      const data = {
        name: this.data?.counterparty,
        inn: this.data?.inn,
        address_city: this.currentCounterparty?.address_city ?? null,
        address_country: this.currentCounterparty?.address_country ?? null,
        address_index: this.currentCounterparty?.address_index ?? null,
        address_street: this.currentCounterparty?.address_street ?? null,
        email: this.currentCounterparty?.email ?? null,
        phone_number: this.currentCounterparty?.phone_number ?? null,
      };
      if (this.data?.inn?.length === 0) {
        this.message = "Количество символов 10-12";
        return;
      }
      this.$store.dispatch("loading", true);

      this.$api
        .post(`${this.$api.defaults.API}/counterparty`, data)
        .then((res) => {
          this.currentCounterparty = res.data;
          this.disabledConterpartiesFields = true;
          this.$store.dispatch("loading", false);
          this.openModal = true;
          this.modalText = "Контрагент создан";
        })
        .catch((err) => {
          new Error(err);
          this.$store.dispatch("loading", false);
          if (
            err?.response?.data?.inn &&
            err?.response?.data?.inn?.[0]?.includes("already exists")
          ) {
            this.message = "Данный ИНН уже существует в нашей базе данных";
          }
        });
    },
    // create end customer
    createEndCustomer() {
      const data = {
        name: this.data?.endCustomer,
        region: this.data?.region,
        inn: this.data?.customerInn,
      };
      if (this.data?.customerInn?.length === 0) {
        this.createCustomerInn = "Количество символов 10-12";
        return;
      }

      this.$api
        .post(`${this.$api.defaults.API}/end-customer`, data)
        .then((res) => {
          this.currentCustomer = res.data;
          this.disabledCustomerField = true;
          this.$store.dispatch("loading", false);
          this.openModal = true;
          this.modalText = "Конечный заказчик создан";
        })
        .catch((err) => {
          new Error(err);
          this.$store.dispatch("loading", false);
          if (
            err?.response?.data?.inn &&
            err?.response?.data?.inn?.[0]?.includes("already exists")
          ) {
            this.createCustomerInn =
              "Данный ИНН уже существует в нашей базе данных";
          }
        });
    },
    // get regions
    getRegions() {
      this.$api.get(`${this.$api.defaults.API}/regions`).then((res) => {
        for (let r of res.data?.results) {
          const opt = {
            key: r.full_name,
            value: r.full_name,
          };
          !this.regions.some((i) => i.key == opt.key) && this.regions.push(opt);
        }
      });
    },
    // close modal
    closeModal() {
      this.openModal = false;
      this.modalText = "Отправлено";
    },
    clearConterparties() {
      this.data.counterparty = "";
      this.data.inn = "";
      this.data.address = "";
      this.data.phone = "";
      this.data.email = "";
      this.counterparties = [];
      this.currentCounterparty = null;
      this.message = "";
    },
    clearCustomers() {
      this.data.endCustomer = "";
      this.data.region = "";
      this.data.customerInn = "";
      this.currentCustomer = null;
      this.customers = [];
      this.createCustomerInn = "";
    },
  },
  watch: {
    data: {
      handler() {
        this.disableActions = true;
      },
      deep: true,
    },
    products: {
      handler() {
        this.disableActions = true;
      },
      deep: true,
    },
  },
  beforeUnmount() {
    // this.offer = null;
    this.counterparties = [];
    this.productSuggestions = [];
    this.currentCounterparty = [];
    this.customers = [];
    this.currentCustomer = [];
    this.productToSend = {};
  },
};
</script>

<style lang="scss" scoped>
.offer-create {
  padding-bottom: 78px;
}
</style>
