<template>
  <table class="offer-table" v-if="!$isMobile">
    <thead>
      <tr>
        <th></th>
        <th>Номер</th>
        <th>Дата создания</th>
        <th>Стоимость</th>
        <th>Контрагент</th>
        <!-- <th>Скидка</th> -->
        <th>Регион</th>
        <th>Файлы</th>
      </tr>
    </thead>
    <tbody ref="table" v-if="offers.length">
      <tr v-for="offer in offers" :key="offer.id">
        <td class="no-wrap">
          <div class="base-check">
            <input
              type="checkbox"
              :id="offer.id"
              :name="offer.id"
              :value="offer.id"
              :disabled="
                offerChecked.length && !offerChecked.includes(offer.id)
              "
              v-model="offerChecked"
            />
            <label :for="offer.id"></label>
          </div>
        </td>
        <td
          @click="
            $router.push({ name: 'offerCreate', query: { id: offer.id } })
          "
        >
          <span>
            {{ offer.id }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ offer.id }}
          </span>
        </td>
        <td
          @click="
            $router.push({ name: 'offerCreate', query: { id: offer.id } })
          "
        >
          <span>
            {{ offer.created_at }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ offer.created_at }}
          </span>
        </td>
        <td
          @click="
            $router.push({ name: 'offerCreate', query: { id: offer.id } })
          "
        >
          <span>{{ offer.total_cost }} {{ getCurrencySymbol(offer) }}</span>
          <span class="tooltip" v-tooltip
            >{{ offer.total_cost }} {{ getCurrencySymbol(offer) }}</span
          >
        </td>
        <td
          @click="
            $router.push({ name: 'offerCreate', query: { id: offer.id } })
          "
        >
          <span>
            {{ offer.counterparty.name }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ offer.counterparty.name }}
          </span>
        </td>
        <!-- <td
          class="no-wrap"
          @click="
            $router.push({ name: 'offerCreate', query: { id: offer.id } })
          "
        >
          <span> {{ offer.counterparty.discount }}% </span>
        </td> -->
        <td
          @click="
            $router.push({ name: 'offerCreate', query: { id: offer.id } })
          "
        >
          <span>
            {{ offer.end_customer && offer.end_customer.region }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ offer.end_customer && offer.end_customer.region }}
          </span>
        </td>
        <td class="no-wrap">
          <div class="offer-actions">
            <a
              :href="formatUrl(offer.kp_pdf_file)"
              class="offer-action _pdf"
              target="_blank"
            >
              <img
                src="@/assets/icons/offer-actions/pdf.svg"
                alt="PDF"
                width="29"
                height="27"
              />
            </a>
            <a
              :href="formatUrl(offer.kp_excel_file)"
              class="offer-action _excel"
              target="_blank"
            >
              <img
                src="@/assets/icons/offer-actions/excel.svg"
                alt="Excel"
                width="29"
                height="27"
              />
            </a>
            <a
              :href="formatUrl(offer.ru_file)"
              class="offer-action _ru"
              target="_blank"
            >
              <img
                src="@/assets/icons/offer-actions/ru.svg"
                alt="Ru"
                width="22"
                height="27"
              />
            </a>
            <div class="offer-action _tz">
              <img
                src="@/assets/icons/offer-actions/tz.svg"
                alt="Tz"
                width="22"
                height="27"
              />
              <div class="offer-action-dropdown">
                <div class="offer-action-dropdown-content">
                  <div class="base-check radio">
                    <input
                      type="radio"
                      :id="`ktru${offer.id}`"
                      value="ktru"
                      v-model="downloadType"
                    />
                    <label :for="`ktru${offer.id}`" class="upp"> ктру </label>
                  </div>
                  <div class="base-check radio">
                    <input
                      type="radio"
                      :id="`tu${offer.id}`"
                      value="tu"
                      v-model="downloadType"
                    />
                    <label :for="`tu${offer.id}`" class="upp"> ту </label>
                  </div>
                  <div class="base-check radio">
                    <input
                      type="radio"
                      :id="`ktrus${offer.id}`"
                      value="ktrus"
                      v-model="downloadType"
                    />
                    <label :for="`ktrus${offer.id}`"
                      ><span class="upp">ктру</span> сокр.
                    </label>
                  </div>
                  <base-button type="filled" @clicked="download(offer.id)">
                    Скачать
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div
    class="offer-table-mobile table-mobile"
    ref="table"
    v-else-if="offers.length && $isMobile"
  >
    <div class="table-mobile-column" v-for="(offer, i) in offers" :key="i">
      <div class="offer-table-mobile-checkbox">
        <div class="base-check">
          <input
            type="checkbox"
            :id="offer.id"
            :name="offer.id"
            :value="offer.id"
            :disabled="offerChecked.length && !offerChecked.includes(offer.id)"
            v-model="offerChecked"
          />
          <label :for="offer.id"></label>
        </div>
      </div>
      <div
        class="table-mobile-row"
        @click="$router.push({ name: 'offerCreate', query: { id: offer.id } })"
      >
        <span class="table-mobile__title"> Номер </span>
        <span class="table-mobile__value">
          {{ offer.id }}
        </span>
      </div>
      <div
        class="table-mobile-row"
        @click="$router.push({ name: 'offerCreate', query: { id: offer.id } })"
      >
        <span class="table-mobile__title"> Дата созд. </span>
        <span class="table-mobile__value">
          {{ offer.created_at }}
        </span>
      </div>
      <div
        class="table-mobile-row"
        @click="$router.push({ name: 'offerCreate', query: { id: offer.id } })"
      >
        <span class="table-mobile__title"> Стоимость </span>
        <span class="table-mobile__value">
          {{ offer.total_cost }} {{ getCurrencySymbol(offer) }}
        </span>
      </div>
      <div
        class="table-mobile-row"
        @click="$router.push({ name: 'offerCreate', query: { id: offer.id } })"
      >
        <span class="table-mobile__title"> Контрагент </span>
        <span class="table-mobile__value">
          {{ offer.counterparty.name }}
        </span>
      </div>
      <!-- <div
        class="table-mobile-row"
        @click="$router.push({ name: 'offerCreate', query: { id: offer.id } })"
      >
        <span class="table-mobile__title"> Скидка </span>
        <span class="table-mobile__value">
          {{ offer.counterparty.discount }}%
        </span>
      </div> -->
      <div
        class="table-mobile-row"
        @click="$router.push({ name: 'offerCreate', query: { id: offer.id } })"
      >
        <span class="table-mobile__title"> Регион </span>
        <span class="table-mobile__value">
          {{ offer.end_customer && offer.end_customer.region }}
        </span>
      </div>
      <div class="offer-actions">
        <a :href="offer.kp_pdf_file" class="offer-action _pdf" target="_blank">
          <img
            src="@/assets/icons/offer-actions/pdf.svg"
            alt="PDF"
            width="29"
            height="27"
          />
        </a>
        <a
          :href="offer.kp_excel_file"
          class="offer-action _excel"
          target="_blank"
        >
          <img
            src="@/assets/icons/offer-actions/excel.svg"
            alt="Excel"
            width="29"
            height="27"
          />
        </a>
        <a :href="offer.ru_file" class="offer-action _ru" target="_blank">
          <img
            src="@/assets/icons/offer-actions/ru.svg"
            alt="Ru"
            width="22"
            height="27"
          />
        </a>
        <div class="offer-action _tz">
          <img
            src="@/assets/icons/offer-actions/tz.svg"
            alt="Tz"
            width="22"
            height="27"
          />
          <div class="offer-action-dropdown">
            <div class="offer-action-dropdown-content">
              <div class="base-check radio">
                <input
                  type="radio"
                  :id="`ktru${offer.id}`"
                  value="ktru"
                  v-model="downloadType"
                />
                <label :for="`ktru${offer.id}`" class="upp"> ктру </label>
              </div>
              <div class="base-check radio">
                <input
                  type="radio"
                  :id="`tu${offer.id}`"
                  value="tu"
                  v-model="downloadType"
                />
                <label :for="`tu${offer.id}`" class="upp"> ту </label>
              </div>
              <div class="base-check radio">
                <input
                  type="radio"
                  :id="`ktrus${offer.id}`"
                  value="ktrus"
                  v-model="downloadType"
                />
                <label :for="`ktrus${offer.id}`"
                  ><span class="upp">ктру</span> сокр.
                </label>
              </div>
              <base-button :type="'filled'" @clicked="download(offer.id)">
                Скачать
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <span class="offer-table-notFound content-notFound" v-if="!offers.length">
    Ничего не найдено
  </span>
</template>

<script>
import BaseButton from "@/components/common/Base.Button.vue";
import { formatUrl, getCurrencySymbol } from "@/helpers/index";

export default {
  name: "OfferTable",
  props: {
    offers: {
      type: Array,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    BaseButton,
  },
  data() {
    return {
      downloadType: "ktru",
    };
  },
  computed: {
    offerChecked: {
      get() {
        return this.modelValue ?? [];
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },
  mounted() {
    setTimeout(() => {
      const el = this.$refs.table;
      el &&
        el.addEventListener("scroll", () => {
          this.onScroll(el);
        });
    }, 1000);
  },
  methods: {
    onScroll(el) {
      let height = +el.getBoundingClientRect().height.toFixed(0);
      let scrollHeight = +el.scrollHeight.toFixed(0);
      let top = +el.scrollTop.toFixed(0);
      this.$emit("scroll", top);
      top + height == scrollHeight && this.$emit("scrolled");
    },
    formatUrl(url) {
      return formatUrl(url);
    },
    getCurrencySymbol(offer) {
      return getCurrencySymbol(offer);
    },
    download(id) {
      const offer = this.offers.find((i) => i.id === id);

      window.open(
        this.formatUrl(
          this.downloadType === "ktru"
            ? offer.krtu_file
            : this.downloadType === "tu"
            ? offer.krtu_gost_file
            : this.downloadType === "ktrus"
            ? offer.krtu_avg_file
            : ""
        ),
        "_blank"
      );
    },
  },
  emits: ["download", "scroll", "scrolled", "update:modelValue"],
};
</script>
