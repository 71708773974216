<template>
  <section class="offer">
    <h1 class="offer__title page__title">Коммерческое предложение</h1>
    <div class="offer-header page-wrapper" :class="{ shadow: scrollStart }">
      <div class="offer-header__buttons">
        <div class="offer_create">
          <base-button
            type="filled"
            @clicked="$router.push({ name: 'offerCreate' })"
          >
            Создать <span class="upp" style="margin-left: 2px"> кп</span>
          </base-button>
        </div>
        <div class="offer_copy">
          <base-button
            type="outlined"
            @clicked="openCopyModal"
            :disabled="!offerChecked.length"
          >
            Копировать
          </base-button>
        </div>
        <div class="offer_delete">
          <base-button
            type="outlined"
            @clicked="openComfirmModal"
            :disabled="!offerChecked.length"
          >
            <svg
              width="23"
              height="24"
              viewBox="0 0 23 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.7832 4.99219L18.2182 18.4272"
                stroke="#FF0000"
                stroke-width="2"
              />
              <path
                d="M18.2188 4.99219L4.78372 18.4272"
                stroke="#FF0000"
                stroke-width="2"
              />
            </svg>
          </base-button>
        </div>
      </div>
      <div class="offer-search">
        <base-input
          :id="'offerSearch'"
          :placeholder="
            !$isMobile
              ? 'Поиск по номеру КП, конечному заказчику, региону, контрагенту'
              : 'Поиск'
          "
          :search="true"
          :searchIcon="true"
          :suggestionWidth="380"
          :suggestions="suggestions"
          @setSuggestion="getOffers"
          @keypressEnter="getOffers"
          @clear="(query = ''), (suggestions = []), getOffers()"
          @input="getOffers('search')"
          v-model="query"
        />
      </div>
      <div class="offer-filters__button" v-if="$isMobile">
        <div
          class="offer-filters__count"
          v-if="Object.values(filters).some((i) => i)"
        >
          {{ Object.values(filters).filter((i) => i !== "").length }}
        </div>
        <base-button type="outlined" @click="open = true">
          <img src="@/assets/icons/filters.svg" alt="Filters" />
        </base-button>
      </div>
    </div>
    <div
      class="offer-filters page-wrapper"
      :class="{ open: showContent }"
      v-if="!$isMobile || ($isMobile && open)"
    >
      <div class="offer-filters__close" v-if="$isMobile">
        <img
          src="@/assets/icons/clear.svg"
          alt="Close"
          @click="closeDropdown"
        />
      </div>
      <h4 class="offer-filters__title page__title" v-if="$isMobile">Фильтры</h4>
      <div class="offer-filters_counterparty">
        <base-input
          :id="'offerSearchCounterparty'"
          :placeholder="'Контрагент'"
          :search="true"
          :suggestions="counterparties"
          @input="getCounterparty('search')"
          @keypressEnter="getOffers"
          @setSuggestion="(filters.counterparty = $event.value), getOffers()"
          @clear="
            (counterparty = ''),
              (filters.counterparty = ''),
              (counterparties = []),
              getOffers()
          "
          v-model="counterparty"
        />
      </div>
      <div class="offer-filters_region">
        <base-select
          :options="regions"
          :placeholder="'Регион'"
          v-model="filters.region"
          @change="getOffers"
        />
      </div>
      <div class="offer-filters_customer">
        <base-select
          :options="customers"
          :placeholder="'Конечный заказчик'"
          v-model="filters.customer"
          @change="getOffers"
        />
      </div>
      <div
        class="offer-filters_manager"
        v-if="user && user.access_group !== 'manager'"
      >
        <base-select
          :options="managers"
          :placeholder="'Менеджер'"
          v-model="filters.manager"
          @change="getOffers"
        />
      </div>
      <div class="offer-filters_price-type">
        <base-select
          :options="priceTypes"
          :placeholder="'Выберите тип цены'"
          v-model="filters.priceType"
          @change="getOffers"
        />
      </div>
      <div class="offer-filters__apply page-buttons" v-if="$isMobile">
        <div class="offer-filters__apply-button">
          <base-button type="filled" @click="closeDropdown">
            Применить
          </base-button>
        </div>
      </div>
    </div>
    <div class="offer-content page-wrapper">
      <offers-table
        :offers="offers"
        @scroll="
          (top) => {
            top > 0 ? (scrollStart = true) : (scrollStart = false);
          }
        "
        @scrolled="getNextPage"
        v-model="offerChecked"
      />
    </div>
  </section>
  <base-modal
    :open="comfirmModalState"
    :text="modalText"
    :comfirm="comfirm"
    @accept="deleteOffer"
    @close="closeModal"
  />
  <base-modal
    :open="copyComfirm"
    @close="(copyComfirm = false), (discount = null)"
  >
    <template #content>
      <div class="offer-copy-modal">
        <base-input
          type="number"
          label="Увеличить цену на ...%"
          placeholder="...%"
          :tableInput="true"
          :max-length="7"
          :max-value="100"
          :symbol="'%'"
          v-model="discount"
        />
        <base-button type="filled" @clicked="copy"> Копировать </base-button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapState } from "vuex";
import BaseSelect from "@/components/common/Base.Select.vue";
import mobileDropdown from "@/mixins/mobileDropdown";
import OffersTable from "./components/OffersTable.vue";

export default {
  components: {
    BaseSelect,
    OffersTable,
  },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      query: "",
      counterparty: "",
      scrollStart: false,
      filters: {
        counterparty: "",
        region: "",
        customer: "",
        manager: "",
        priceType: "",
      },
      regions: [
        {
          key: "Не Выбран",
          value: "",
        },
      ],
      customers: [
        {
          key: "Не Выбран",
          value: "",
        },
      ],
      priceTypes: [
        {
          key: "Не Выбран",
          value: "",
        },
        {
          key: "Стандартный",
          value: "standard",
        },
        {
          key: "Коммерческий",
          value: "commercial",
        },
        {
          key: "Валютный",
          value: "currency",
        },
      ],
      copyComfirm: false,
      discount: null,
      comfirm: false,
      modalText: "Вы уверены?",
      priceType: "",
      page: 1,
      hasNext: false,
      offerChecked: [],
      offers: [],
      counterparties: [],
      suggestions: [],
      comfirmModalState: false,
      managers: [
        {
          key: "Не Выбран",
          value: "",
        },
      ],
    };
  },
  mounted() {
    this.getOffers();
    this.getRegions();
    this.getCustomers();
    if (this.user && this.user.access_group !== "manager") this.getManagers();
  },
  methods: {
    openCopyModal() {
      this.copyComfirm = true;
    },
    // offer copy
    async copy() {
      this.$store.dispatch("loading", true);
      const offer = this.offers.find((i) => i.id == this.offerChecked[0]);
      const products = [];
      if (offer.offer_products.length) {
        for (let p of offer.offer_products) {
          products.push({
            product: p.product,
            price: p.price,
            discount: p.discount,
            count: p.count,
            price_per_package: p.price_per_package,
            unit: p.unit,
            cost: p.cost,
          });
        }
      }

      const data = {
        counterparty: (offer.counterparty && offer.counterparty.id) || null,
        end_customer: (offer.end_customer && offer.end_customer.id) || null,
        company: (offer.company && offer.company.id) || null,
        // add product discount by checked discount
        offer_products: products?.map?.((p) => {
          if (this.discount) {
            // change product price after copy
            p.price = (
              +p?.price +
              (+p?.price * +this.discount) / 100
            )?.toFixed?.(2);
            // change product price per package after copy
            p.price_per_package = (
              +p?.price_per_package +
              (+p?.price_per_package * +this.discount) / 100
            )?.toFixed?.(2);

            // change product cost
            const currentPrice =
              p?.unit?.toLowerCase?.() === "упак."
                ? p?.price_per_package
                : p?.price;

            p.cost = (
              +currentPrice -
              (+currentPrice * +p?.discount) / 100
            )?.toFixed?.(2);
          }

          return p;
        }),
        tech_docs_format: offer.tech_docs_format,
      };

      await this.$api
        .post(`${this.$api.defaults.API}/offer`, data)
        .then((res) => {
          this.$router.push({
            name: "offerCreate",
            query: {
              id: res.data.id,
            },
          });
        });
      await this.$store.dispatch("loading", false);
    },
    // get counterparties
    getCounterparty() {
      this.$api
        .get(
          `${this.$api.defaults.API}/counterparty?search=${this.counterparty}`
        )
        .then((res) => {
          this.counterparties = [];
          for (let c of res.data.results) {
            // get counterparties
            const counterparty = {
              key: c.name,
              value: c.id,
            };
            if (
              !this.counterparties.find(
                (c) => c.value === counterparty.value
              ) &&
              counterparty.key &&
              this.counterparty
            )
              this.counterparties.push(counterparty);
          }
        });
    },
    // get managers
    getManagers() {
      this.$api.get(`${this.$api.defaults.API}/user/managers`).then((res) => {
        for (let m of res.data) {
          this.managers.push({
            key: m.fio,
            value: m.id,
          });
        }
      });
    },
    // get regions
    getRegions() {
      this.$api.get(`${this.$api.defaults.API}/regions`).then((res) => {
        for (let r of res.data?.results) {
          const opt = {
            key: r.full_name,
            value: r.full_name,
          };
          !this.regions.some((i) => i.key == opt.key) && this.regions.push(opt);
        }
      });
    },
    // get customers
    getCustomers() {
      this.$api.get(`${this.$api.defaults.API}/end-customer`).then((res) => {
        for (let c of res.data.results) {
          this.customers.push({
            key: c.name,
            value: c.id,
          });
        }
      });
    },
    // get offers
    getOffers(type = "") {
      if (type !== "loading") this.page = 1;
      if (type !== "search") this.$store.dispatch("loading", true);
      let params = {
        page: this.page,
        [this.query && "search"]: this.query,
        [this.filters.counterparty && "counterparty"]:
          this.filters.counterparty,
        [this.filters.customer && "end_customer"]: this.filters.customer,
        [this.filters.region && "end_customer__region"]: this.filters.region,
        [this.filters.manager && "owner"]: this.filters.manager,
        [this.filters.priceType && "price_type"]: this.filters.priceType,
      };
      // clear couterparty and main suggestions
      this.counterparties = [];
      this.suggestions = [];
      // general request
      this.$api
        .get(`${this.$api.defaults.API}/offer`, { params })
        .then((res) => {
          this.hasNext = res.data.next;
          this.$store.dispatch("loading", false);
          if (type !== "search" && type !== "loading") this.offers = [];
          // loop for get all fields values
          for (const o of res.data.results) {
            if (type !== "search") this.offers.push(o);
            else {
              // get search suggestions
              if (this.query && !this.suggestions.some((i) => i.id === o.id)) {
                this.suggestions.push({
                  key: o.id,
                  value: o.id,
                });
              }
            }
          }
        })
        .catch((err) => {
          new Error(err);
          this.$store.dispatch("loading", false);
          if (!type) this.offers = [];
        });
    },
    // pagination
    getNextPage() {
      if (this.hasNext) {
        this.page++;
        this.getOffers("loading");
      }
    },
    // open remove modal
    openComfirmModal() {
      this.comfirm = true;
      this.modalText = "Вы уверены?";
      this.comfirmModalState = true;
    },
    // close delete modal
    async closeModal() {
      this.comfirmModalState = false;
      this.comfirm = true;
      this.modalText = "Вы уверены?";
    },
    // delete offer
    async deleteOffer() {
      const id = this.offerChecked?.[0] ?? null;
      this.$store.dispatch("loading", true);

      await this.$api
        .patch(`${this.$api.defaults.API}/offer/${id}`, {
          is_deleted: true,
        })
        .then(async () => {
          this.comfirm = false;
          this.modalText = "КП Удален";
          await this.getOffers();
          this.offerChecked = [];
          this.$store.dispatch("loading", false);
        })
        .catch((err) => {
          new Error(err);
          this.$store.dispatch("loading", false);
        });
    },
  },
  mixins: [mobileDropdown],
};
</script>
