<template>
  <div
    class="based-textarea"
    :class="{ 'based-textarea-error': message, 'based-textarea-row': label }"
  >
    <label :for="id" v-if="label"> {{ label }}</label>
    <textarea
      ref="textarea"
      :class="{ error }"
      :id="id"
      :name="id"
      :required="required"
      :disabled="disabled"
      :readonly="readonly"
      :placeholder="placeholder"
      :value="modelValue"
      :minLength="minLength ? minLength : ''"
      :maxlength="maxLength ? maxLength : ''"
      @input="$emit('update:modelValue', $event.target.value), $emit('input')"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @click="$emit('click')"
      @keypress.enter="$emit('keypressEnter')"
      @keypress="$emit('keypress')"
    />
    <!-- error message -->
    <span class="based-textarea__msg" v-if="error">
      {{ message }}
    </span>
  </div>
</template>

<script>
export default {
  name: "BaseTextarea",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
      default: "",
    },
    error: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
    },
    message: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    minLength: {
      type: Number,
    },
    maxLength: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
  },
  methods: {},
  emits: [
    "update:modelValue",
    "clear",
    "input",
    "focus",
    "blur",
    "click",
    "keypressEnter",
    "keypress",
  ],
};
</script>
