<template>
  <table class="offer-create-table" v-if="!$isMobile">
    <thead>
      <tr>
        <th>Артикул</th>
        <th>Наименование</th>
        <th>Цена</th>
        <th>Кол-во</th>
        <th>Ед. изм-ия</th>
        <th>Скидка</th>
        <th>Стоимость</th>
        <th></th>
      </tr>
    </thead>
    <tbody v-if="products.length">
      <tr v-for="(p, i) in productsArray" :key="i">
        <td>
          <span>
            {{ p.article }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ p.article }}
          </span>
        </td>
        <td>
          <span>
            {{ p.name }}
          </span>
          <span class="tooltip" v-tooltip>
            {{ p.name }}
          </span>
        </td>
        <td class="no-wrap">
          <template v-if="p.unit === 'упак.'">
            <base-input
              input-type="text"
              :tableInput="true"
              :symbol="getCurrencySymbol()"
              v-model="p.price_per_package"
            />
          </template>
          <template v-else>
            <base-input
              input-type="text"
              :tableInput="true"
              :symbol="getCurrencySymbol()"
              v-model="p.price"
            />
          </template>
        </td>
        <td class="no-wrap">
          <base-input type="number" :tableInput="true" v-model="p.count" />
        </td>
        <td class="no-wrap">
          <base-select :options="productUnits(p)" v-model="p.unit" />
        </td>
        <td class="no-wrap">
          <base-input
            type="number"
            :tableInput="true"
            :max-length="7"
            :max-value="99"
            :symbol="'%'"
            v-model="p.discount"
          />
        </td>
        <td>
          <span> {{ p.cost.toFixed(2) }} {{ getCurrencySymbol() }} </span>
          <span class="tooltip" v-tooltip>
            {{ p.cost.toFixed(2) }} {{ getCurrencySymbol() }}
          </span>
        </td>
        <td class="no-wrap">
          <span
            class="_remove"
            @click="
              () => {
                $emit('removeProduct', p);
              }
            "
          >
            <img src="@/assets/icons/clear.svg" alt="Remove" />
          </span>
        </td>
      </tr>
    </tbody>
  </table>
  <div
    class="offer-create-table-mobile table-mobile"
    ref="table"
    v-else-if="products.length && $isMobile"
  >
    <div class="table-mobile-column" v-for="(p, i) in productsArray" :key="i">
      <span
        class="_remove"
        @click="
          () => {
            $emit('removeProduct', p);
          }
        "
      >
        <img src="@/assets/icons/clear.svg" alt="Remove" />
      </span>
      <div class="table-mobile-row">
        <span class="table-mobile__title"> Артикул </span>
        <span class="table-mobile__value">
          {{ p.article }}
        </span>
      </div>
      <div class="table-mobile-row">
        <span class="table-mobile__title"> Наим-ие </span>
        <span class="table-mobile__value">
          {{ p.name }}
        </span>
      </div>
      <div class="table-mobile-row">
        <span class="table-mobile__title"> Цена </span>
        <span class="table-mobile__value">
          <template v-if="p.unit === 'упак.'">
            <base-input
              input-type="text"
              :tableInput="true"
              :symbol="getCurrencySymbol()"
              v-model="p.price_per_package"
            />
          </template>
          <template v-else>
            <base-input
              input-type="text"
              :tableInput="true"
              :symbol="getCurrencySymbol()"
              v-model="p.price"
            />
          </template>
        </span>
      </div>
      <div class="table-mobile-row">
        <span class="table-mobile__title"> Кол-во </span>
        <span class="table-mobile__value">
          <base-input type="number" :tableInput="true" v-model="p.count" />
        </span>
      </div>
      <div class="table-mobile-row">
        <span class="table-mobile__title"> Ед. изм-ия </span>
        <span class="table-mobile__value">
          <base-select :options="productUnits(p)" v-model="p.unit" />
        </span>
      </div>
      <div class="table-mobile-row">
        <span class="table-mobile__title"> Скидка </span>
        <span class="table-mobile__value">
          <base-input
            type="number"
            :tableInput="true"
            :max-length="7"
            :max-value="99"
            :symbol="'%'"
            v-model="p.discount"
          />
        </span>
      </div>
      <div class="table-mobile-row">
        <span class="table-mobile__title"> Стоимость </span>
        <span class="table-mobile__value">
          {{ p.cost.toFixed(2) }} {{ getCurrencySymbol() }}
        </span>
      </div>
    </div>
  </div>
  <div class="offer-create-price" v-if="products.length">
    <div>
      <div class="offer-create-price__discount">
        <span> Скидка </span>
        <span>
          {{ discountedPrice.toFixed(2) }} {{ getCurrencySymbol() }}
        </span>
      </div>
      <div class="offer-create-price__total">
        <span> Общая стоимость </span>
        <span> {{ totalPrice.toFixed(2) }} {{ getCurrencySymbol() }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrencySymbol } from "@/helpers/index";
import BaseSelect from "@/components/common/Base.Select.vue";

export default {
  name: "OfferCreateTable",

  props: {
    products: {
      type: Array,
    },
    priceType: {
      type: String,
      default: "",
    },
  },

  components: {
    BaseSelect,
  },

  computed: {
    productsArray() {
      const products = this.products?.map((p) => {
        const price =
          p?.unit?.toLowerCase?.() === "упак."
            ? p?.price_per_package ?? 0
            : p?.price ?? 0;

        const cost = +price * +p.count ?? 1;
        p.cost = +cost - (+cost * +p.discount ?? 1) / 100;

        return p;
      });
      return products;
    },

    // get discounted price
    discountedPrice() {
      let disc = 0;
      this.productsArray?.forEach?.((p) => {
        const price =
          p?.unit?.toLowerCase?.() === "упак." ? p.price_per_package : p?.price;

        disc += (+price * +p.count * +p.discount) / 100;
      });
      return disc;
    },

    // get total price
    totalPrice() {
      let price = 0;
      this.productsArray.forEach((p) => (price += +p.cost));
      return price;
    },

    priceTypeProp() {
      return this.priceType;
    },

    productUnits() {
      return (p) => {
        if (p.price_per_package) {
          return [
            {
              key: "шт.",
              value: "шт.",
            },
            {
              key: "упак.",
              value: "упак.",
            },
          ];
        }

        return [
          {
            key: "шт.",
            value: "шт.",
          },
        ];
      };
    },
  },

  methods: {
    getCurrencySymbol() {
      return getCurrencySymbol(this.priceTypeProp);
    },
  },

  emits: ["removeProduct"],
};
</script>
