<template>
  <div
    class="base-select"
    v-click-outside="closeDropdown"
    :class="{ 'base-select-border': borderOnly }"
    ref="select"
  >
    <div
      class="base-select-selected"
      :class="[
        { open, disabled },
        {
          'base-select-selected-border': borderOnly,
          active: modelValue && modelValue !== '',
        },
      ]"
      @click="openDropdown"
    >
      <span v-if="!modelValue || (modelValue == '' && placeholder)">
        {{ placeholder }}
      </span>
      <span class="selected ellipse" v-else>
        {{ selected && selected.key }}
      </span>
    </div>
    <div
      v-if="!disabled && open"
      class="base-select-items"
      :class="{ 'base-select-items-border': borderOnly }"
      ref="dropdown"
      :style="dropdownDirection"
    >
      <div
        v-for="(option, i) of options"
        :key="`${option.value}_${i}`"
        :class="{ active: modelValue == option.value }"
        @click="
          selected = option;
          open = false;
          $emit('update:modelValue', option.value);
          $emit('change');
        "
      >
        {{ option.key }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: [Number, String],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    borderOnly: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
    },
    yellowBg: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selected: {
      get() {
        return this.options.find((i) => i.value === this.modelValue) || "";
      },
      set() {
        this.$emit("update:modelValue");
      },
    },
  },
  data() {
    return {
      open: false,
      dropdownDirection: null,
    };
  },
  methods: {
    openDropdown() {
      if (!this.disabled) this.open = !this.open;
      if (this.open) {
        setTimeout(() => {
          let selectTop = this.$refs.select.getBoundingClientRect().top;
          let dropdownHeight =
            this.$refs.dropdown.getBoundingClientRect().height;
          if (window.innerHeight - selectTop < dropdownHeight + 60)
            this.dropdownDirection = `top: auto; bottom: ${
              this.borderOnly ? 23 : 40
            }px`;
          else this.dropdownDirection = `top: ${this.borderOnly ? 23 : 40}px`;
        });
      }
    },

    closeDropdown() {
      this.open = false;
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
